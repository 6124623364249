import { Card, CardActionArea } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import Carousel from "react-material-ui-carousel";

const popularSlideSettings = {
    autoPlay: true,
    animation: "slide",
    indicators: true,
    duration: 500,
    navButtonsAlwaysVisible: false,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
    fullHeightHover: true,
    swipe: true
};

export default function PopularBrands({ popularBrands }){
    let br_link = popularBrands.map((br, index) => {
        return br.brand;
    }).join('--').toUpperCase();
    return(
        <div className="w-full">
            <h1 className="p-2 text-xl font-semibold text-center text-white bg-red-600 w-full mx-auto">MOST POPULAR BRANDS</h1>
                <div className='relative p-4 '>
                    <Link href={`/shop?b=`+br_link} passHref>
                        <div className='group mx-auto cursor-pointer hover:bg-red-600 hover:text-white text-red-600 bg-white font-bold rounded-xl border border-red-500 md:w-[270px] w-full p-1'>
                            <span className='cursor-pointer text-center text-xl md:text-2xl font-bold whitespace-nowrap flex flex-row flex-wrap justify-center'>
                                <span className='flex-grow px-1 text-shadow-md'>SHOP NOW</span>
                            </span>
                        </div>
                    </Link>
                </div>
                <div className='md:hidden'>
                    <Carousel
                        className="popular-brands"
                        {...popularSlideSettings}
                    >
                        {
                            popularBrands?.map((brand, index) => {
                                let cb = brand.brand.toUpperCase();
                                return(
                                    <Link
                                        key={index}
                                        href={'/shop?b='+cb}
                                        passHref
                                    >
                                        <div
                                            className="p-1"
                                        >
                                            <Card className="rounded-lg shadow-md w-full h-full">
                                                <CardActionArea>
                                                    <Image
                                                        width={600}
                                                        height={650}
                                                        className="w-full h-[5rem] md:h-[9.25rem] rounded-xl cursor-pointer"
                                                        src={brand.image_dash}
                                                        alt={brand.brand}
                                                    />
                                                </CardActionArea>
                                            </Card>
                                        </div>
                                    </Link>
                                );
                            })
                        }
                    </Carousel>
                </div>
                <div className='hidden md:flex flex-row '>
                    {popularBrands.map((brand, index) => {
                        let cb = brand.brand.toUpperCase();
                        return(
                            <Link
                                className='w-full'
                                key={index}
                                href={'/shop?b='+cb}
                                passHref
                            >
                                <div
                                    className="p-1 w-full"
                                >
                                    <Card className="rounded-lg shadow-md w-full h-full px-4 py-2">
                                        <CardActionArea>
                                            <Image
                                                width={600}
                                                height={600}
                                                className="w-full h-8 md:h-36 rounded-xl cursor-pointer"
                                                src={brand.image_dash}
                                                alt={brand.brand}
                                                quality={75}
                                            />
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </Link>
                        );
                    })}
                </div>
        </div>
    );
}