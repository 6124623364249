import Brands from '@/components/Homepage/Brands';
import PopularBrands from '@/components/Homepage/Brands/Popular';
import HowToOrder from '@/components/Homepage/HowToOrder';
import PaymentList from '@/components/Homepage/Payments';
import PromoCarousel from '@/components/Homepage/Promos/Carousel';
import Specification from '@/components/Homepage/Specification';
import Testimonials from '@/components/Homepage/Testimonials';
import Promotions from '@/components/Homepage/Promotions';
import SearchForm from '@/components/Product/Search/Form';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import path from 'path';
import fsPromises from 'fs/promises';
import { parse } from 'zipson';
import PropTypes from 'prop-types';
const MAKES_JSON_DIR = path.join(process.cwd(), "public", "jsons/makes.json");
const WIDTHS_JSON_DIR = path.join(process.cwd(), "public", "jsons/widths.json");
const BRANDS_JSON_DIR = path.join(process.cwd(), "public", "jsons/brands.json");

const Layout = dynamic(() => import('@/components/Layout'), {
    loading: () => 'Loading...',
});

Home.propTypes = {
    tire_width: PropTypes.string.isRequired,
    tire_make: PropTypes.string.isRequired,
    promo_list: PropTypes.array,
    popular_brands: PropTypes.array,
    gbrands: PropTypes.string,
    promotion: PropTypes.object,
};

export default function Home({ tire_width, tire_make, promo_list, popular_brands, gbrands, promotion }) {
    const [widths, setWidths] = useState(null);
    const [makes, setMakes] = useState(null);
    const [brands, setBrands] = useState(null);
    useEffect(()=>{
        if(!tire_make) return;
        setMakes(parse(tire_make));
        if(!tire_width) return;
        setWidths(parse(tire_width));
        if(!gbrands) return;
        setBrands(parse(gbrands));
    },[gbrands, tire_make, tire_width]);
	return(
		<Layout>
            <div className='bg-search-box bg-fixed bg-cover bg-no-repeat bg-center'>
                <section className={`w-full flex lg:flex-row flex-col align-middle justify-center py-2`}>
                    <div className='relative max-w-screen-md w-full mx-auto'>
                        <SearchForm className="min-w-full" tireMake={makes} tireWidth={widths}/>
                    </div>
                </section>
                <section className='bg-white/60 w-full'>
                    <PromoCarousel promoList={promo_list}/>
                </section>
                <section className='w-full bg-white/90 border border-red-600 pb-4'>
                    <PopularBrands popularBrands={popular_brands}/>
                </section>
                <section className="w-full bg-white/90">
                    <Specification/>
                </section>
                <section className="w-full bg-white/90">
                    <HowToOrder/>
                </section>
                <section className="w-full bg-red-100/90">
                    <Testimonials/>
                </section>
                <section className="w-full bg-white/90">
                    <Brands brandList={brands}/>
                </section>
                <section className="w-full bg-white/90">
                    <PaymentList/>
                </section>
                <section className="w-full bg-red-100/90">
                    <Promotions promotion={promotion}/>
                </section>
            </div>
		</Layout>
	)
}

export async function getServerSideProps(context) {

    let tire_width = [];
    try {
        const jsonData = await fsPromises.readFile(WIDTHS_JSON_DIR, {encoding: 'utf8'});
        tire_width = jsonData;
    } catch (error) {
        console.log(error);
    }
    let tire_make = [];
    try {
        const jsonData = await fsPromises.readFile(MAKES_JSON_DIR, {encoding: 'utf8'});
        tire_make = jsonData;
    } catch (error) {
        console.log(error);
    }

    let promo_list = [];
    try {
        const pl = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/promo_list`);
        promo_list = await pl.json();
    } catch (error) {
        console.log(error);
    }

    let popular_brands = [];
    try {
        const pb = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/popular_brands`);
        popular_brands = await pb.json();
    } catch (error) {
        console.log(error);
    }

    let promotions = [];
    try {
        const pr = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/promotion`);
        promotions = await pr.json();
    } catch (error) {
        console.log(error);
    }

    let brands = [];
    try {
        const jsonData = await fsPromises.readFile(BRANDS_JSON_DIR, {encoding: 'utf8'});
        brands = jsonData;
    } catch (error) {
        console.log(error);
    }

    return {
        props: {
            tire_width: tire_width,
            tire_make: tire_make,
            promo_list: promo_list,
            popular_brands: popular_brands,
            gbrands: brands,
            promotion: promotions,
        },
    }
}
