import { Box, CardContent, CardMedia, Grid, Typography } from "@mui/material";

export default function Specification(){
    return(
        <div className="max-w-screen-2xl mx-auto shadow-sm pt-10">
            <Grid container spacing={1} className="">
                <Grid item className="w-1/3 md:w-1/6">
                    <CardMedia
                        component="img"
                        sx={{ width: 80, height: 80 }}
                        src="/assets/images/step/hto_1.webp"
                        alt="Best Price"
                        className="mx-auto"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }} className='text-center'>
                            <Typography className="gulong-font-b text-base md:text-xl" component="div">
                                Free Delivery
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item className="w-1/3 md:w-1/6">
                    <CardMedia
                        component="img"
                        sx={{ width: 80, height: 80 }}
                        src="/assets/images/step/hto_3.webp"
                        alt="Warranty"
                        className="mx-auto"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }} className='text-center'>
                            <Typography className="gulong-font-b text-base md:text-xl" component="div">
                                Manufacturer&apos;s Warranty
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item className="w-1/3 md:w-1/6">
                    <CardMedia
                        component="img"
                        sx={{ width: 80, height: 80 }}
                        src="/assets/images/step/hto_2.webp"
                        alt="Free Installation"
                        className="mx-auto"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }} className='text-center'>
                            <Typography className="gulong-font-b text-base md:text-xl" component="div">
                                Free Installation
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item className="w-1/3 md:w-1/6">
                    <CardMedia
                        component="img"
                        sx={{ width: 80, height: 80 }}
                        src="/assets/images/step/hto_4.webp"
                        alt="Brand New Tires"
                        className="mx-auto"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }} className='text-center'>
                            <Typography className="gulong-font-b text-base md:text-xl" component="div">
                                100% All Brand New
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item className="w-1/3 md:w-1/6">
                    <CardMedia
                        component="img"
                        sx={{ width: 80, height: 80 }}
                        src="/assets/images/step/hto_5.webp"
                        alt="Order in 3 Steps"
                        className="mx-auto"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }} className='text-center'>
                            <Typography className="gulong-font-b text-base md:text-xl" component="div">
                                Exclusive Discounts
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item className="w-1/3 md:w-1/6">
                    <CardMedia
                        component="img"
                        sx={{ width: 80, height: 80 }}
                        src="/assets/images/step/hto_6.webp"
                        alt="Order in 3 Steps"
                        className="mx-auto"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }} className='text-center'>
                            <Typography className="gulong-font-b text-base md:text-xl" component="div">
                                <h1 className="">Hassle-free Tire Ordering Online</h1>
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}