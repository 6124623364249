import Image from "next/image";

const payments = [
    {
        name: "Gcash",
        image: "/assets/images/payment/GCash.webp",
    },
    {
        name: "PayPal",
        image: "/assets/images/payment/Paypal.webp",
    },
    {
        name: "Mastercard",
        image: "/assets/images/payment/Mastercard.webp",
    },
    {
        name: "VISA",
        image: "/assets/images/payment/Visa.webp",
    },
    {
        name: "billease",
        image: "/assets/images/payment/Billease.webp",
    },
];
export default function PaymentList(){
    return(
        <div className="max-w-screen-lg mx-auto py-4">
            <h1 className="text-center w-full text-2xl md:text-4xl text-red-600 pb-2 font-bold">Available Payment Options</h1>
            <div className="flex flex-row flex-wrap justify-center">
                {payments.map((payment, index)=>{
                    return(
                        <div key={index} className="md:w-1/5 w-1/2 p-1">
                            <div className="bg-white rounded-md shadow-md flex align-middle justify-center px-2">
                                <Image
                                    src={payment.image}
                                    alt={payment.name}
                                    width={600}
                                    height={450}
                                    className=""
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}