import { Box, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import Image from "next/image";

export default function HowToOrder(){
    return(
        <div className="max-w-screen-2xl mx-auto shadow-sm">
            <Image width={1000} height={1000} className="md:w-[30%] h-full mx-auto mb-4" src={'/assets/images/hto/how_to_order.webp'} alt="how-to-order" />
            <hr className="py-2"/>
            <Grid container spacing={3} className="p-6">
                <Grid item className="w-full md:w-1/3" sx={{ display: 'flex' }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 110, height: 110 }}
                        src="/assets/images/hto/ht1.webp"
                        alt="Best Price"
                        className="p-2"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }} className='text-left'>
                            <Typography className="gulong-font-b" component="div" variant="h6">
                                Find and
                            </Typography>
                            <Typography className="gulong-font-b" component="div" variant="h6">
                                Choose your
                            </Typography>
                            <Typography className="gulong-font-b" component="div" variant="h6">
                                Preferred Tires
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item className="w-full md:w-1/3" sx={{ display: 'flex' }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 110, height: 110 }}
                        src="/assets/images/hto/ht2.webp"
                        alt="Free Installation"
                        className="p-2"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }} className='text-left'>
                            <Typography className="gulong-font-b" component="div" variant="h6">
                                Choose an
                            </Typography>
                            <Typography className="gulong-font-b" component="div" variant="h6">
                                Installation Partner  & Get an appointment
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item className="w-full md:w-1/3" sx={{ display: 'flex' }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 110, height: 110 }}
                        src="/assets/images/hto/ht3.webp"
                        alt="Warranty"
                        className="p-2"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }} className='text-left'>
                            <Typography className="gulong-font-b" component="div" variant="h6">
                                Go To Store on Your Appointment Date to fit your tires
                            </Typography>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}