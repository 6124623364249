import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useRouter, withRouter } from 'next/router'
import Carousel from "react-material-ui-carousel";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import XIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  paper: {
    padding: theme.spacing(0, 0, 0, 0),
  },
}));

export default function Promotions({ promotion, ...props }){
    const router = useRouter();
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(true);
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    return(
        <div {...props}>
          {promotion?.id && (
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                >
                <Fade in={openModal}>
                    <div className="relative p-0 shadow-md outline-none lg:w-screen/95 w-screen/90 m-auto cursor-pointer">
                        <span className="z-10 cursor-pointer absolute right-0 rounded-full border border-gray-400 bg-white" type="button" onClick={handleCloseModal}>
                            <XIcon className="cursor-pointer block h-6 w-6" aria-hidden="true" />
                        </span>
                            <div className="relative w-full h-full">
                                <div className="">
                                <a
                                    onClick={() => {
                                      window.dataLayer = window.dataLayer || [];
                                      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                                      window.dataLayer.push({
                                        'event': 'promotionClick',
                                        'ecommerce': {
                                          'promoClick': {
                                            'promotions': [
                                            {
                                              'id': promotion.id,                         // Name or ID is required.
                                              'name': promotion.name,
                                              'creative': promotion.creative,
                                              'position': promotion.position
                                            }]
                                          }
                                        },
                                        'eventCallback': function() {
                                          router.push(promotion.page_link);
                                        }
                                      });
                                    }}
                                    >
                                    <Image
                                        className="imageCustom"
                                        src={promotion.image_link.replace('cdn.', '')}
                                        alt={promotion.name}
                                        fill
                                    />
                                </a>
                                </div>
                            </div>
                    </div>
                </Fade>
            </Modal>
          )}
        </div>
    );
}
