import Image from "next/image";
import Link from "next/link";

export default function Brands({ brandList }){
    return(
        <div className="max-w-screen-lg mx-auto">
            <h1 className="text-2xl md:text-4xl text-center text-red-600 font-bold py-4">All Tire Brands with Free Installation</h1>
            <div className="flex flex-row flex-wrap justify-evenly align-middle">
                {brandList?.map((brand, index) => {
                    return (
                        <Link
                            className="lg:w-1/4 md:w-1/3 w-1/2"
                            key={index}
                            href={"/brand/"+brand.slug}
                            passHref
                        >
                            <div className="p-1">
                                <div className="flex align-middle px-1 py-px justify-center bg-white shadow-md rounded-md cursor-pointer h-[72px]">
                                    <Image
                                        src={brand.image ? brand.image : '/assets/images/tire_brand_default.webp'}
                                        alt={brand.brand}
                                        width={600}
                                        height={600}
                                        quality={80}
                                        className="w-full"
                                    />
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}