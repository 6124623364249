import { Card, CardActionArea, Typography } from "@mui/material";
import Image from "next/image";
import StarIcon from '@mui/icons-material/Star';
import Carousel from "react-material-ui-carousel";

const reviews = [
    {
        star: [1,2,3,4,5],
        comment: "Great Service! Hassle free service! Will buy tires again here in the future! They also provide great warranty terms!",
        customer_img: "/assets/images/customer/cr1.png",
        name: "Cedez Xerm",
    },
    {
        star: [1,2,3,4,5],
        comment: "Inquired online and appointment set all within 15 minutes. Best Digital customer service I&apos;ve encounterd which makes the whole tire care ordeal much easier.",
        customer_img: "/assets/images/customer/cr2.png",
        name: "Anthony Leyva",
    },
    {
        star: [1,2,3,4,5],
        comment: "Murang gulong Convenient and hassle free. Very responsive sa mga inquiries and with tie up/accredited service center na reliable.",
        customer_img: "/assets/images/customer/cr3.png",
        name: "Immie Ravago Salazar",
    },
];

const settings = {
    autoPlay: true,
    animation: "slide",
    indicators: true,
    duration: 800,
    navButtonsAlwaysVisible: false,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
    fullHeightHover: true,
    swipe: true
};

function CommentBox({ star, comment, name, customerImg}){
    return(
        <div className="md:w-full w-[95%] min-h-[505px] flex flex-col bg-white max-w-md mx-auto px-4 py-2 border-red-100 border rounded-md">
            <p className="text-center py-2">
                {star.map((s, index) => {
                    return(
                        <StarIcon key={index} className="text-blue-900"/>
                    )
                })}
            </p>
            <p className="text-semibold text-xl text-center py-2">
                &ldquo;{comment}&rdquo;
            </p>
            <Image
                width={600}
                height={450}
                className="rounded-lg cursor-pointer"
                src={customerImg}
                alt={name}
            />
        </div>
    )
}
export default function Testimonials(){
    return(
        <div className="w-full pb-2">
            <p className="text-2xl md:text-4xl text-center text-red-600 font-bold py-4">WHY TIRE BUYERS LOVE US</p>
            <div className="">
                <Carousel
                    className="testimonials"
                    {...settings}
                >
                    {reviews.map((r, index)=>{
                        return(
                            <CommentBox
                                key={index}
                                star={r.star}
                                comment={r.comment}
                                name={r.name}
                                customerImg={r.customer_img}
                            />
                        )
                    })}
                </Carousel>
            </div>
        </div>
    )
}