import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";

const settings = {
    autoPlay: true,
    animation: "slide",
    indicators: false,
    duration: 500,
    navButtonsAlwaysVisible: false,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
    fullHeightHover: true,
    swipe: true
};

export default function PromoCarousel({ promoList, mini = null, ...props }){
    const [promos, setPromos] = useState(null);
    useEffect(()=>{
        if(!promoList) return;
        let plBy2 = [];
        let tempArr = [];
        promoList.forEach((e, i) => {
            let n = i + 1;
            tempArr.push(e);
            if(n % 2 == 0){
                plBy2.push(tempArr);
                tempArr = [];
            }
            else{
                if(promoList.length == n){
                    plBy2.push(tempArr);
                }
            }
        });
        setPromos(plBy2);
    },[promoList]);
    return(
        <div {...props}>
            {!mini && (
                <Carousel
                    className="promos md:block hidden overflow-hidden sm:max-h-full max-h-[311px]"
                    {...settings}
                >
                    {promos?.map((slide, index) => {
                        return(
                            <div key={index} className="flex flex-row justify-center gap-1">
                                {slide.map((e, x) => {
                                    return(
                                        <Link
                                            key={x}
                                            href={e.brand ? `/shop?b=`+e.brand : '/shop'}
                                            passHref
                                        >
                                            <div
                                                key={index}
                                                className="h-full w-full p-1"
                                            >
                                                <Image
                                                    width={600}
                                                    height={600}
                                                    className="w-full rounded-xl cursor-pointer border-1 border-black"
                                                    src={e.img_dash}
                                                    alt={e.name}
                                                    priority
                                                />
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        );
                    })}
                </Carousel>
            )}
            <Carousel
                className="promos md:hidden block overflow-hidden sm:max-h-full max-h-[211px]"
                {...settings}
            >
              {promoList?.map((slide, index) => {
                  return(
                      <Link
                          key={index}
                          href={slide.brand ? `/shop?b=`+slide.brand : '/shop'}
                          passHref
                      >
                          <div
                              key={index}
                              className="h-full p-1 mx-auto"
                          >
                              <Image
                                  width={600}
                                  height={600}
                                  className="mx-auto rounded-xl cursor-pointer border-1 border-black"
                                  src={slide.img_dash}
                                  alt={slide.name}
                                  priority
                              />
                          </div>
                      </Link>
                  );
              })}

            </Carousel>
        </div>
    );
}
